@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
@layer base { 
    body {
        @apply font-[Raleway];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}
body {
    background-color: #0a192f;
    background-image: url('../src/assets/abstract_background_jmphillips_tall.png');
    background-size: cover;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.group[data-hover-image] {
    background-size: cover;
    background-position: top center;
    transition: background-image 0.3s ease-in-out;
}

.navbar-container {
    margin-bottom: -1.5rem;
    z-index: 9;
}
.skills-container li:hover {
    cursor: default;
}
.works .content-div {
    height: 360px;
}
#root {
    padding-top: 0.5rem;
}

@media screen and (max-width: 750px) {
    .h-screen.w-full {
        height: auto;
        margin-bottom: 3rem;
    }
    #about.h-screen.w-full  {
        margin: 0 auto 3rem;
        max-width: 95%;
    }
    .home h2 {
        margin-top: 4rem;
    }
    .button-down {
        display: none;
    }
}
@media screen and (max-height: 800px) {
    .main-heading {
        margin-top: 2rem;
    }
    .main-photo {
        max-width: 400px;
    }
    .max-w-screen-lg,
    .max-w-\[1000px\],
    .contact.w-full {
        max-width: 800px !important;
    }
    .main .text-2xl,
    .skills .text-2xl {
        font-size: 1.25rem;
    }
    .button-down {
        display: none;
    }
    .works {
        scale: 0.75;
    }
}
@media screen and (max-height: 1000px) {
    .main-heading {
        font-size: 2.5rem !important;
    }
}